<template>
	<v-dialog
		v-model="userDialog.visible"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<!-- user dialog content -->
		<v-card>
			<v-toolbar
				dark
				color="primary"
			>
				<v-toolbar-title> {{ userDialog.title }} </v-toolbar-title>
				<v-spacer></v-spacer>

				<v-btn
					icon
					dark
					@click="closeDialog"
				>
					<v-icon> mdi-close </v-icon>
				</v-btn>
			</v-toolbar>

			<!-- dialog content -->
			<v-card-text style="padding-top: 20px; padding-bottom: 0px; max-width: 800px; margin: 0 auto;" v-if="userDialog.state != dialogStates.ERROR">
				<!-- worker section -->
				<v-card-text
					style='font-weight: bold; background-color: #bdbdbd; color: black;'
					text
				>
					<span>WORKER</span>
					<span v-if="userDialog.workerState == assignedWorkerStates.ASSIGNED"> ({{userDialog.user.firstName}} {{userDialog.user.lastName}})</span>
				</v-card-text>

				<v-card-text style="padding-left: 15px; padding-right: 15px;" >
					<div v-if="userDialog.workerState == assignedWorkerStates.NOT_ASSIGNED">
						<v-radio-group 
							style="margin-top: 0"
							v-model="workerOptionChosen"
							:error="userDialog.errors.workerOption.isError"
							:error-messages="userDialog.errors.workerOption.errorMessage"
							@change="userDialog.errors.workerOption.isError = false; userDialog.errors.workerOption.errorMessage = null"
						>
							<v-radio
								v-for="workerOption in workerOptions"
								:key="workerOption.id"
								:label="workerOption.label"
								:value="workerOption.id"
							></v-radio>
						</v-radio-group>

						<UsersWorkerAutocompleter
							v-model="userDialog.user.workerId"
							v-if="workerOptionChosen == 3"
							style="padding-top: 0px; padding-bottom: 0px; max-width: 400px;"
							label="Pick worker"
							:propApiLink="workerAutocompleteLink"
							:error="userDialog.errors.workerId.isError"
							:errorMessages="userDialog.errors.workerId.errorMessage"
							@input="onAutocompleterInput"
						></UsersWorkerAutocompleter>

						<div v-if="workerOptionChosen == 3 && userDialog.user.workerId == null" style="margin-bottom:10px; color:orange">
							<v-icon small color="orange">mdi-alert-circle-outline</v-icon> {{lviews.assignWorkerMessage}}
						</div>

						<div>
                        <v-icon small>mdi-information-outline</v-icon>  {{lviews.assigningWorkerEnabledPermissionsMessage }}
						</div>
					</div>

					<div v-if="userDialog.workerState == assignedWorkerStates.ASSIGNED">
						<v-flex xs12 v-if="userDialog.user.workerId != null">
							<v-btn :disabled="!userDeletable" @click="openYesNoDialog"> Delete </v-btn>
							<v-icon v-if="!userDeletable" small color="orange" style="margin-left: 10px;">mdi-alert-circle-outline</v-icon> <span v-if="!userDeletable">{{ lviews.cannotDeleteWorkerUsedInApp }}</span>
							<div style="margin-top: 10px;"> {{lviews.otherUserFunctionalitiesMessage}}  </div>
						</v-flex>
					</div>
				</v-card-text>
				<v-divider></v-divider>

				<!-- basic data -->
				<v-card-text
					style='font-weight: bold; background-color: #bdbdbd; color: black;'
					text
				>
					BASIC DATA
				</v-card-text>

				<v-card-text style="padding-left: 15px; padding-right: 15px; margin-bottom: 10px;">
					<v-row style="margin-top: 10px;">
						<!-- first name edition -->
						<div class="user-dialog-row-item">
							<div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;">First name</div>
							<v-text-field
								v-model="userDialog.user.firstName"
								:error="userDialog.errors.firstName.isError"
								:error-messages="userDialog.errors.firstName.errorMessage"
								:rules="validationRules.firstNameRules"
								outlined
								dense
								height="10px"
								required
								maxlength="30"
								counter
								@input="() => { userDialog.errors.firstName.valueEntered = true; setFieldError('firstName', false, ''); createUserLoginIfPossible(); }"
							></v-text-field>
						</div>

						<!-- last name edition -->
						<div class="user-dialog-row-item">
							<div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;">Last name</div>
							<v-text-field
								v-model="userDialog.user.lastName"
								:error="userDialog.errors.lastName.isError"
								:error-messages="userDialog.errors.lastName.errorMessage"
								:rules="validationRules.lastNameRules"
								outlined
								dense
								height="10px"
								required
								maxlength="30"
								counter
								@input="() => { userDialog.errors.lastName.valueEntered = true; setFieldError('lastName', false, ''); createUserLoginIfPossible(); }"
							></v-text-field>
						</div>
					</v-row>

					<v-row>
						<!-- email edition -->
						<div class="user-dialog-row-item">
							<div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;">Email</div>
							<v-text-field
								v-model="userDialog.user.email"
								:error="userDialog.errors.email.isError"
								:error-messages="userDialog.errors.email.errorMessage"
								:rules="validationRules.emailRules"
								outlined
								dense
								height="10px"
								required
								maxlength="254"
								counter
								@input="() => { userDialog.errors.email.valueEntered = true; setFieldError('email', false, ''); }"
							></v-text-field>
						</div>

						<!-- phone number edition -->
						<div class="user-dialog-row-item">
							<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;">Phone number</div>

							<v-text-field
								v-model="userDialog.user.phoneNumber"
								:error="userDialog.errors.phoneNumber.isError"
								:error-messages="userDialog.errors.phoneNumber.errorMessage"
								:rules="validationRules.phoneNumberRules"
								outlined
								dense
								height="10px"
								maxlength="128"
								counter
								@input="() => { userDialog.errors.phoneNumber.valueEntered = true; setFieldError('phoneNumber', false, ''); }"
							></v-text-field>
						</div>
					</v-row>

					<v-row>
						<!-- COMMENT EDITION-->
						<div class="user-dialog-row-item">
							<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;">Comment</div>
							<v-text-field
								v-model="userDialog.user.comment"
                                outlined>                                
                            </v-text-field>
						</div>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>

                <!-- login -->
				<v-card-text
					color="black"
					style='font-weight: bold; background-color: #bdbdbd; color: black;'
					text
				>
					USER (LOGIN)
				</v-card-text>

				<v-card-text style="padding-left: 15px; padding-right: 15px;">
					<v-row style="margin-top: 5px;">
						<div class="user-dialog-row-item">
							<div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;">Login</div>

							<v-text-field
								v-model="userDialog.user.login"
								required
								outlined
								dense
								height="10px"
								:error="userDialog.errors.login.isError"
								:error-messages="userDialog.errors.login.errorMessage"
								:rules="validationRules.loginRules"
								@input="() => { userDialog.errors.login.valueEntered = true; setFieldError('login', false, ''); }"
								class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
							></v-text-field>
						</div>
					</v-row>
				</v-card-text>

                <!-- basic data -->
				<v-card-text
					style='font-weight: bold; background-color: #bdbdbd; color: black;'
					text
				>
					PERSONAL DATA
				</v-card-text>

				<v-card-text style="padding-left: 15px; padding-right: 15px; margin-bottom: 10px;">
					<v-row>
						<!-- birth date edition -->
						<div class="user-dialog-row-item">
							<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;">Birth date</div>
							<v-dialog
								v-model="userDialog.birthDateOpened"
								width="290px"
								persistent
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="userDialog.user.birthDate"
										append-icon="mdi-calendar"
										readonly
										outlined
										dense
										height="10px"
										v-bind="attrs"
										v-on="on"
										:error="userDialog.errors.birthDate.isError"
										:error-messages="userDialog.errors.birthDate.errorMessage"
										@change="() => { setFieldError('birthDate', false, ''); }"
									></v-text-field>
								</template>

								<v-card>
									<v-date-picker
										:min="userDialog.minBirthDate"
										:max="userDialog.maxBirthDate"
										v-model="userDialog.user.birthDate"	
										@change="() => { setFieldError('birthDate', false, ''); }"									
									></v-date-picker>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn
											text
											@click="() => {userDialog.birthDateOpened = false; userDialog.user.birthDate='';}"
										>
											Cancel
										</v-btn>
										<v-btn
											color="primary"
											text
											@click="() => { (userDialog.user.birthDate != '') ? userDialog.birthDateOpened = false : null;}"
										>
											Save
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>

						</div>

						<!-- external ID edition -->
						<div class="user-dialog-row-item">
							<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;">External ID</div>
							<v-text-field
								type="tel"
								v-model="userDialog.user.personalId"
								outlined
								dense
								counter
								height="10px"
								:error="userDialog.errors.personalId.isError"
								:error-messages="userDialog.errors.personalId.errorMessage"
								@input="() => { userDialog.errors.personalId.valueEntered = true; setFieldError('personalId', false, ''); }"
								@keypress="isDigit($event)"
								maxlength="50"
							></v-text-field>
						</div>
					</v-row>
				</v-card-text>

				<!-- permissions -->
				<v-card-text
					color="black"
					style='font-weight: bold; background-color: #bdbdbd; color: black;'
					text
				>
					PERMISSIONS
				</v-card-text>

				<v-card-text style="padding-left: 15px; padding-right: 15px; margin-bottom: 10px;">
					<v-row style="margin-top: 5px;">
						<!-- role -->
						<div class="user-dialog-row-item">
							<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;">Role</div>
							<v-select
								v-model="userDialog.user.role"
								:items="roles"
								outlined
								dense
								height="10px"
								multiple
								:error="userDialog.errors.role.isError"
								:error-messages="userDialog.errors.role.errorMessage"
								@input="() => { userDialog.errors.role.valueEntered = true; setFieldError('role', false, ''); }"
								item-value="id"
								item-text="name"
							>
								<template v-slot:selection="{ item, index }">
									<v-chip v-if="userDialog.user.role.length === 1" style = "margin:0;">
										<span class = "role-name-cut-when-too-long">{{ item.name }}</span>
									</v-chip>

									<v-chip v-else-if="userDialog.user.role.length > 1 && index===0" style = "margin:0;">
										<span class = "role-name-cut-when-too-long">{{ item.name }} (...)</span>
									</v-chip> 
								</template>
							</v-select>

                            <v-checkbox
								style="margin-top: 0px;"
								v-if="userId == null"
								v-model="userDialog.user.sendActivationMail"
								label="Send activation mail (immediately)"
							></v-checkbox>
						</div>

						<!-- data access -->
						<div class="user-dialog-row-item">
							<div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;">Data access</div>
							<v-select
								v-model="userDialog.user.dataAccess"
								:items="dataAccess"
								outlined
								dense
								height="10px"
								multiple
								:error="userDialog.errors.dataAccess.isError"
								:error-messages="userDialog.errors.dataAccess.errorMessage"
								@input="() => { userDialog.errors.dataAccess.valueEntered = true; setFieldError('dataAccess', false, ''); }"
								item-value="id"
								item-text="name"
							>
								<template v-slot:selection="{ item, index }">
									<v-chip v-if="userDialog.user.dataAccess.length === 1" style = "margin:0;">
										<span class = "role-name-cut-when-too-long">{{ item.name }}</span>
									</v-chip>

									<v-chip v-else-if="userDialog.user.dataAccess.length > 1 && index===0" style = "margin:0;">
										<span class = "role-name-cut-when-too-long">{{ item.name }} (...)</span>
									</v-chip> 
								</template>
							</v-select>
						</div>
					</v-row>
				</v-card-text>

				<!-- Global errors -->
				<div class="global-errors" v-if="userDialog.globalErrors != null && userDialog.globalErrors.length != 0">
					<span v-for="(gerr, gerrKey) in userDialog.globalErrors" :key="gerrKey">
						{{ gerr }}<br />
					</span>
				</div>

				<v-divider></v-divider>
			</v-card-text>

			<!-- error displaying -->
			<v-card-text v-else>
				<v-layout style="padding-top: 80px; padding-bottom: 10px;" class="content-middler" column justify-center align-center>
					<v-icon size="100"> mdi-emoticon-sad-outline </v-icon>
					<div class="title" style="width:90%; text-align: center; margin-top:10px;"> 
						{{ globalErrorMessage }}
					</div>
				</v-layout>
			</v-card-text>

			<!-- dialog bottom buttons -->
			<v-card-actions class="justify-end" style="padding: 10px 24px 35px 24px; max-width: 800px; margin: 0 auto" v-if="userDialog.state != dialogStates.ERROR">
				<v-btn @click="userDialog.cancelClick">Cancel </v-btn>
				<v-btn color="primary" @click="saveChanges()"> Save </v-btn>
			</v-card-actions>

			<!-- error understood button -->
			<v-card-actions class="justify-center" style="padding: 10px 24px 35px 24px;" v-else>
				<v-btn color="primary" @click="userDialog.cancelClick"> OK </v-btn>
			</v-card-actions>
		</v-card>


		<!-- remove yes/no dialog -->
		<v-dialog 
			persistent 
			v-model="yesNoDialogVisible"
			max-width="295"
		>
			<v-card>
				<v-card-title class="headline">{{lviews.yesNoDialogTitle}}</v-card-title>

				<v-card-text style="text-align:justify">{{ removeUserWorkerContent }}</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="grey"
						text
						@click="() => { yesNoDialogVisible = false; }">
						Cancel
					</v-btn>

					<v-btn color="red darken-1"
						text
						@click="removeAssignedWorker">
						Delete
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Add existing user to current tenant yes/no dialog -->
		<v-dialog 
			persistent 
			v-model="addUserToTenantDialog.visible"
			max-width="450"
		>
			<v-card>
				<v-card-title class="headline">User already exists in other tenant</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="text-align:justify" class="py-0">
					<p class="font-weight-regular black--text mt-6">Do you want to add existing user to current tenant?</p>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="grey"
						text
						@click="() => { addUserToTenantDialog.visible = false; }"
					>No</v-btn>

					<v-btn color="primary darken-1"
						text
						@click="addUserToTenantDialog.visible = false, saveChanges(addUserToTenantDialog.existingUserId)"
					>Yes</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- close user dialog confirmation popup -->
		<v-dialog 
			persistent 
			v-model="closeUserDialogConfirmation.visible"
			max-width="295"
		>
			<v-card>
				<v-card-title>Wait</v-card-title>
				<v-card-text style="text-align:justify"> {{lviews.closeUserDialogConfirmationContent }}</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="grey"
						text
						@click="() => { closeUserDialogConfirmation.visible = false; }">
						No
					</v-btn>

					<v-btn color="red darken-1"
						text
						@click="closeDialogConfirmCallback">
						Yes
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
//import axios from 'axios';
const axios = require('axios');
import appConfig from '@/app_config'
// Takes too long! Moved to beforeCreate
//import PhoneNumber from 'awesome-phonenumber';
//const PhoneNumber = require('awesome-phonenumber');
//import UsersWorkerAutocompleter from './UsersWorkerAutocompleter'
const UsersWorkerAutocompleter = () => import('./UsersWorkerAutocompleter');
var PhoneNumber = null;

export default {
	async beforeCreate() {
		PhoneNumber = await import('awesome-phonenumber');
	},

	components: {
		UsersWorkerAutocompleter,
	},

	data: function() {
		return {
			userDialog: {
				visible: false,
				user: {
					id: null,
					workerId: null,

					// basic data
					lastName: null,
					firstName: null,
					email: null,
					phoneNumber: null,
					comment: "",

					// personal data
					birthDate: null,
					personalId: null,

					// security
					role: [],
					dataAccess: [],

					// login
					login: "",
					sendActivationMail: false,
				},

				birthDateOpened: false,

				globalErrors: null,
				errors: {
					workerId: {
						isError: false,
						errorMessage: "",
						valueEntered: false
					},
					workerOption: {
						isError: false,
						errorMessage: "",
						valueEntered: false
					},
					lastName: {
						isError: false,
						errorMessage: "",
						valueEntered: false,
					},
					firstName: {
						isError: false,
						errorMessage: "",
						valueEntered: false,
					},
					email: {
						isError: false,
						errorMessage: "",
						valueEntered: false,
					},
					phoneNumber: {
						isError: false,
						errorMessage: "",
						valueEntered: false,
					},
					birthDate: {
						isError: false,
						errorMessage: "",
						valueEntered: false,
					},
					personalId: {
						isError: false,
						errorMessage: "",
						valueEntered: false,
					},
					role: {
						isError: false,
						errorMessage: "",
						valueEntered: false,
					},
					dataAccess: {
						isError: false,
						errorMessage: "",
						valueEntered: false,
					},
					login: {
						isError: false,
						errorMessage: "",
						valueEntered: false,
					},
				},

				validators: {
					lastName: this.validateLastName,
					firstName: this.validateFirstName,
					email: this.validateEmail,
					phoneNumber: this.validatePhoneNumber,
					birthDate: this.validateBirthDate,
					login: this.validateLogin,
					workerId: this.validateWorkerId
				},

				title: "",
				cancelClick: () => {},
				confirmClick: () => {},
				state: null,
				workerState: null,

				minBirthDate: null,
				maxBirthDate: null,
			},

			userSourceObj: {
				workerId: null,
				id: null,

				// basic data
				lastName: "",
				firstName: "",
				email: null,
				phoneNumber: null,

				// personal data
				birthDate: "",
				personalId: null,

				// security
				role: [],
				dataAccess: [],

				// login
				username: "",
			},

			dialogStates: {
				NEW: 0,
				EXISTING: 1,
				ERROR: 2,
			},
			assignedWorkerStates: {
				NOT_ASSIGNED: 0,
				ASSIGNED: 1
			},

			validationRules: {
				lastNameRules: [ v => this.validateLastName(v) || this.userDialog.errors.lastName.errorMessage ],
				firstNameRules: [ v => this.validateFirstName(v) || this.userDialog.errors.firstName.errorMessage ],
				emailRules: [ v => this.validateEmail(v) || this.userDialog.errors.email.errorMessage ],
				loginRules: [ v => this.validateLogin(v) || this.userDialog.errors.login.errorMessage ],
				phoneNumberRules: [ v => this.validatePhoneNumber(v) || this.userDialog.errors.phoneNumber.errorMessage ],
				birthDateRules: [ v => this.validateBirthDate(v) || this.userDialog.errors.birthDate.errorMessage ],
			},

			// worker radio button option
			workerOptionChosen: null,
			workerOptions: [
				{
					id: 1,
					label: "" // no worker
				},
				{
					id: 2,
					label: "" // new worker
				},
				{
					id: 3,
					label: "" // existing worker
				}
			],
			existingWorkerId: null,
			globalErrorMessage: "",

			userDeletable: false,
			displayRadioButtons: false,
			removeUserWorkerContent: "",
			workerAutocompleteLink: "",
			yesNoDialogVisible: false,

			// close edit/create user dialog data structure
			closeUserDialogConfirmation: {
				visible: false,
			},
			addUserToTenantDialog: {
				visible: false,
				existingUserId: null
			}
		}
	},

	methods: {
		/**
		 * Generates login for user if we create new one
		 */
		createUserLoginIfPossible: function() {
			if (this.userDialog.state == this.dialogStates.NEW) {
				this.userDialog.user.login=""
				if (this.userDialog.user.firstName != null && this.userDialog.user.firstName != "") {
					this.userDialog.user.login = this.userDialog.user.firstName.toLowerCase();
				}
				if (this.userDialog.user.lastName != null && this.userDialog.user.lastName != "") {
					if (this.userDialog.user.firstName != null && this.userDialog.user.firstName != "") {
						this.userDialog.user.login += ".";
					}
					this.userDialog.user.login += this.userDialog.user.lastName.toLowerCase();
				}
			}
		},

		/**
		 * Turns error value on/off according to passed field value (one of userDialog keys).
		 * @param field {String} Name of userDialog key for which error is turned on/off.
		 * @param value {Boolean} Value for 'isError' attribute for specified field.
		 * @param message {String} Error message (may be empty for turning error off).
		 */
		setFieldError: function(field, value, message) {
			this.userDialog.errors[field].isError = value;
			this.userDialog.errors[field].errorMessage = message;
		},

		// FIELD VALIDATORS
		/**
		 * Validates last name field value and updates last name error field data.
		 * @param lastNameValue {String} Last name value to validate.
		 * @returns {Boolean} Boolean value whether last name value is valid or not.
		 */
		validateLastName: function(lastNameValue) {
			if (this.userDialog.errors.lastName.valueEntered) {
				if (lastNameValue == "" || lastNameValue == null || lastNameValue == undefined) {
					this.setFieldError("lastName", true, "Last name required");
					return false;
				}

				if (lastNameValue.length > 50) {
					this.setFieldError("lastName", true, "Last name too long");
					return false;
				}

				this.setFieldError("lastName", false, "");
				return true;
			}
			this.setFieldError("lastName", false, "");
			return true;
		},

		/**
		 * Validates first name field value and updates first name error field data.
		 * @param firstNameValue {String} First name value to validate.
		 * @returns {Boolean} Boolean value whether first name value is valid or not.
		 */
		validateFirstName: function(firstNameValue) {
			if (this.userDialog.errors.firstName.valueEntered) {
				if (firstNameValue == "" || firstNameValue == null || firstNameValue == undefined) {
					this.setFieldError("firstName", true, "First name required");
					return false;
				}
				
				if (firstNameValue.length > 30) {
					this.setFieldError("firstName", true, "First name too long");
					return false;
				}
				
				
				this.setFieldError("firstName", false, "");
				return true;
			}
			this.setFieldError("firstName", false, "");
			return true;
		},

		/**
		 * Validates email field value and updates email error field data.
		 * @param emailValue {String} Email value to validate.
		 * @returns {Boolean} Boolean value whether email value is valid or not.
		 */
		validateEmail: function(emailValue) {
			if (this.userDialog.errors.email.valueEntered) {
				if (emailValue == "" || emailValue == null || emailValue == undefined) {
					this.setFieldError("email", true, "Email required");
					return false;
				}

				let result = /.+@.+\..+/.test(emailValue);
				if (!result) {
					this.setFieldError("email", true, "Invalid email structure");
					return false;
				}

				this.setFieldError("email", false, "");
				return true;
			}
			this.setFieldError("email", false, "");
			return true;
		},

		/**
		 * Validates role field value and updates role error field data.
		 * @param roleArray {[Number]} Array of role identifiers to validate.
		 * @returns {Boolean} Boolean value whether role field is filled correctly or not.
		 */
		validateRole: function(roleArray) {
			if (this.userDialog.errors.role.valueEntered) {
				if (roleArray == null || roleArray == undefined || roleArray == "" || !roleArray) {
					this.setFieldError("role", true, "Role required");
					return false;
				}

				this.setFieldError("role", false, "");
				return true;
			}
			return true;
		},

		/**
		 * Validates data access field value and updates data access error field data.
		 * @param dataAccessArray {[Number]} Array of data access permissions identifiers to validate.
		 * @returns {Boolean} Boolean value whether data access field is filled correctly or not.
		 */
		validateDataAccess: function(dataAccessArray) {
			if (this.userDialog.errors.dataAccess.valueEntered) {
				if (dataAccessArray == null || dataAccessArray == undefined || dataAccessArray == "" || !dataAccessArray) {
					this.setFieldError("dataAccess", true, "Data access required");
					return false;
				}

				this.setFieldError("dataAccess", false, "");
				return true;
			}
			return true;
		},

		/**
		 * Validates login field value and updates login error field data.
		 * @param loginValue {String} User's login to validate.
		 * @returns {Boolean} Boolean value whether login field is valid or not.
		 */
		validateLogin: function(loginValue) {
			if (this.userDialog.errors.login.valueEntered) {
				if (loginValue == null || loginValue == "" || loginValue == undefined || !loginValue) {
					this.setFieldError("login", true, "Login field value required");
					return false;
				}

				this.setFieldError("login", false, "");
				return true;
			}
			return true;
		},

		/**
		 * Validates phone number field value (PL, GB, US, DE, UA) and updates phone number error field data.
		 * @param phoneNumberValue {String} User's phone number to validate.
		 * @returns {Boolean} Boolean value whether phone number is valid or not.
		 */
		validatePhoneNumber: function(phoneNumberValue) {
			if (this.userDialog.errors.phoneNumber.valueEntered) {
				if (phoneNumberValue != null && phoneNumberValue != "") {
					let validators = [
						new PhoneNumber(phoneNumberValue, "PL"),
						new PhoneNumber(phoneNumberValue, "GB"),
						new PhoneNumber(phoneNumberValue, "DE"),
						new PhoneNumber(phoneNumberValue, "UA"),
						new PhoneNumber(phoneNumberValue, "US")
					];

					let anyValidatorValid = false, value;
					validators.forEach(validator => {
						value = validator.isValid();
						if (value) {
							anyValidatorValid = true;
						}
					});

					if (!anyValidatorValid) {
						this.setFieldError("phoneNumber", true, "Invalid phone number structure");
						return false;
					}

					if (!phoneNumberValue.toString().startsWith('+')) {
						this.setFieldError("phoneNumber", true, this.lviews.validators.phoneNumber.areaCode);
						return false;
					}

					this.setFieldError("phoneNumber", false, "");
					return true;
				}
				this.setFieldError("phoneNumber", false, "");
				return true;
			}
			return true;
		},

		/**
		 * Validates birth date field value and updates birth date error field data.
		 * @param birthDateValue {String} User's birth date to validate.
		 * @returns {Boolean} Boolean value whether birth date is valid or not (whether field is empty or not).
		 */
		validateBirthDate: function(/*birthDateValue*/) {
			if (this.userDialog.errors.birthDate.valueEntered) {
				/* // Required Birth Date - commented out in ClockInt  
				if (birthDateValue == null || birthDateValue == undefined || birthDateValue == "" || !birthDateValue) {
					this.setFieldError("birthDate", true, "Birth date required");
					return false;
				}
				*/
				this.setFieldError("birthDate", false, "");
				return true;
			}
			return true;
		},
		validateWorkerId: function(workerId) {
			if (workerId == null || typeof(workerId) === 'undefined' || workerId == ''){
				if(this.workerOptionChosen == 3){
					this.setFieldError("workerId", true, "Field required");

					return false;
				}
			}
			this.setFieldError("workerId", false, "");
			return true;
		},

		// OTHER METHODS

		/**
		 * Adapts user details response data to userDialog.user structure
		 */
		assignEditUserDataUsingProps: function() {
			// assign modifying object data
			this.userDialog.user.workerId = this.userData.worker;
			this.userDialog.user.id = this.userData.auth_user.id;
			this.userDialog.user.lastName = this.userData.last_name;
			this.userDialog.user.firstName = this.userData.first_name;
			this.userDialog.user.email = this.userData.email;
			this.userDialog.user.phoneNumber = this.userData.phone_number;
			this.userDialog.user.birthDate = this.userData.birth_date;
			this.userDialog.user.personalId = this.userData.unique_personal_id;
			this.userDialog.user.role = this.userData.rolesIds;
			this.userDialog.user.dataAccess = this.userData.dataAccessIds;
			this.userDialog.user.comment = this.userData.comment;
			this.userDialog.user.login = this.userData.auth_user.username;


			// assign source object data -> to verify whether changes were provided or not
			this.userSourceObj.workerId = this.userData.worker;
			this.userSourceObj.id = this.userData.auth_user.id;
			this.userSourceObj.lastName = this.userData.last_name;
			this.userSourceObj.firstName = this.userData.first_name;
			this.userSourceObj.comment = this.userData.comment;
			this.userSourceObj.email = this.userData.personal_email;
			this.userSourceObj.phoneNumber = this.userData.personal_mobile_number;
			this.userSourceObj.birthDate = this.userData.birth_date;
			this.userSourceObj.personalId = this.userData.unique_personal_id;
			this.userSourceObj.role = this.userData.rolesIds;
			this.userSourceObj.dataAccess = this.userData.dataAccessIds;
			this.userSourceObj.username = this.userData.auth_user.username;

			// assigning whether user can be deleted or not
			this.userDeletable = this.userData.deletable;
		},

		/**
		 * Resets user dialog
		 */
		resetUserDialog: function () {
			this.userDialog.user = {
				id: null,
				workerId: null,

				// basic data
				lastName: "",
				firstName: "",
				email: null,
				phoneNumber: null,
				comment: "",

				// personal data
				birthDate: "",
				personalId: null,

				// security
				role: [],
				dataAccess: [],

				// login
				login: "",
				sendActivationMail: false,
			};

			this.userSourceObj = {
				id: null,
				workerId: null,

				// basic data
				lastName: "",
				firstName: "",
				email: null,
				phoneNumber: null,
				comment: "",

				// personal data
				birthDate: "",
				personalId: null,

				// security
				role: [],
				dataAccess: [],

				// login
				username: "",
				sendActivationMail: false,
			};

			this.userDialog.globalErrors = null;
			let errorKeys = Object.keys(this.userDialog.errors);
			errorKeys.forEach(key => {
				this.setFieldError(key, false, "");
				this.userDialog.errors[key].valueEntered = false;
			});

		},

		/**
		 * Opens create new / edit user dialog
		 */
		openDialog: async function() {
			if (this.userId == null) {
				this.displayRadioButtons = true;
				this.workerOptionChosen = 1;
				this.resetUserDialog();
				this.userDialog.title = "Add user";
				this.userDialog.state = this.dialogStates.NEW;
				this.userDialog.workerState = this.assignedWorkerStates.NOT_ASSIGNED;
			}
			else {
				this.resetUserDialog();
				this.userDialog.title ="Edit user";
				this.userDialog.state = this.dialogStates.EXISTING;
				this.assignEditUserDataUsingProps();
				if (this.userDialog.user.workerId == null) {
					this.workerOptionChosen = 1;
					this.displayRadioButtons = true;
					this.userDialog.workerState = this.assignedWorkerStates.NOT_ASSIGNED;
				}
				else {
					this.displayRadioButtons = false;
					this.userDialog.workerState = this.assignedWorkerStates.ASSIGNED;
				}
			}
			this.userDialog.visible = true;
		},

		/**
		 * Opens "close edit/create user dialog" confirmation dialog
		 */
		closeDialog: function() {
			if (this.userDialog.state == this.dialogStates.NEW) {
				// any changes
				if (this.userDialog.user.lastName != "" || this.userDialog.user.firstName != "" || this.userDialog.user.email != null || this.userDialog.user.phoneNumber != null || this.userDialog.user.birthDate != "" || this.userDialog.user.personalId != null || this.userDialog.user.comment!="" || this.userDialog.user.role.length > 0 || this.userDialog.user.dataAccess.length > 0 || this.userDialog.user.login != "" || this.userDialog.user.sendActivationMail || this.workerOptionChosen != 1) {
					this.closeUserDialogConfirmation.visible = true;
				}
				// no changes provided
				else {
					this.userDialog.visible = false;
				}
			}
			else {
				// check if changes provided for existing user
				if (this.userSourceObj.workerId != this.userDialog.user.workerId ||
					this.userSourceObj.lastName != this.userDialog.user.lastName ||
					this.userSourceObj.firstName != this.userDialog.user.firstName ||
					this.userSourceObj.email != this.userDialog.user.email ||
					this.userSourceObj.phoneNumber != this.userDialog.user.phoneNumber ||
					this.userSourceObj.birthDate != this.userDialog.user.birthDate || 
					this.userSourceObj.personalId != this.userDialog.user.personalId ||
					this.userSourceObj.comment != this.userDialog.user.comment ||
					this.userSourceObj.username != this.userDialog.user.login) {
					this.closeUserDialogConfirmation.visible = true;
					return;
				}

				if (this.userSourceObj.role.length != this.userDialog.user.role.length || 
					this.userSourceObj.dataAccess.length != this.userDialog.user.dataAccess.length) {
					this.closeUserDialogConfirmation.visible = true;
					return;
				}
				else {
					let changesProvided = false;
					
					// role changes
					for (let i = 0; i < this.userSourceObj.role.length; i++) {
						if (!this.userDialog.user.role.includes(this.userSourceObj.role[i])) {
							changesProvided = true;
						}
					}

					for (let i = 0; i < this.userDialog.user.role.length; i++) {
						if (!this.userSourceObj.role.includes(this.userDialog.user.role[i])) {
							changesProvided = true;
						}
					}

					// data access changes
					for (let i = 0; i < this.userSourceObj.dataAccess.length; i++) {
						if (!this.userDialog.user.dataAccess.includes(this.userSourceObj.dataAccess[i])) {
							changesProvided = true;
						}
					}

					for (let i = 0; i < this.userDialog.user.dataAccess.length; i++) {
						if (!this.userSourceObj.dataAccess.includes(this.userDialog.user.dataAccess[i])) {
							changesProvided = true;
						}
					}

					if (changesProvided) {
						this.closeUserDialogConfirmation.visible = true;
						return;
					}
				}
				this.userDialog.visible = false;
			}
		},

		/**
		 * Closes edit/create user dialog
		 */
		closeDialogConfirmCallback: function() {
			this.userDialog.visible = false;
			for(let key in this.userDialog.errors){
				this.userDialog.errors[key].isError=false
				this.userDialog.errors[key].errorMessage=""
				this.userDialog.errors[key].valueEntered=false
			}
		},

		/**
		 * Creates object with user edit request data
		 */
		getEditUserRequestData: function() {
			let updateRequestData = {
				personal_data: {},
				auth_user: {
					permissions: [],
				}
			};

			// personal_data
			// if (this.userSourceObj.lastName != this.userDialog.user.lastName) {
				updateRequestData.personal_data["last_name"] = this.userDialog.user.lastName;
			// }
			// if (this.userSourceObj.firstName != this.userDialog.user.firstName) {
				updateRequestData.personal_data["first_name"] = this.userDialog.user.firstName;
			// }
			// if (this.userSourceObj.email != this.userDialog.user.email) {
				updateRequestData.personal_data["email"] = this.userDialog.user.email;
			// }
			// if (this.userSourceObj.phoneNumber != this.userDialog.user.phoneNumber) {
				updateRequestData.personal_data["phone_number"] = this.userDialog.user.phoneNumber;
			// }
			if (this.userSourceObj.personalId != this.userDialog.user.personalId) {
				if (this.userDialog.user.personalId == "" || this.userDialog.user.personalId == null) {
					updateRequestData.personal_data["unique_personal_id"] = null;
				}
				else {
					updateRequestData.personal_data["unique_personal_id"] = this.userDialog.user.personalId;
				}
			}
			if (this.userSourceObj.birthDate != this.userDialog.user.birthDate) {
				if (this.userDialog.user.birthDate == "" || this.userDialog.user.birthDate == null) {
					updateRequestData.personal_data["birth_date"] = null;
				} else {
					updateRequestData.personal_data["birth_date"] = this.userDialog.user.birthDate;
				}
			}

			// auth_user
			updateRequestData.auth_user["group"] = [...this.userDialog.user.role, ...this.userDialog.user.dataAccess];
			if (this.userSourceObj.username != this.userDialog.user.login) {
				updateRequestData.auth_user["username"] = this.userDialog.user.login;
			}
			updateRequestData.auth_user["unique_personal_id"]=this.userDialog.user.unique_personal_id
			
			// currently worker was unassigned, so check if it should be assigned after this request
			if (this.userSourceObj.workerId == null) {
				updateRequestData['worker_option'] = this.workerOptionChosen;
				if (this.workerOptionChosen == 3) {
					updateRequestData['worker_id'] = this.userDialog.user.workerId;
				}
			}
			return updateRequestData;
		},

		/**
		 * Saves user changes / Creates new user in database /Add existing user to current tenant
		 */
		saveChanges: async function(existingUserId=null) {
			let anyErrorOccured = false;
			let keys = Object.keys(this.userDialog.validators);
			for (let i = 0; i < keys.length; i++) {
				let validator = this.userDialog.validators[ keys[i] ];
				this.userDialog.errors[ keys[i] ].valueEntered = true;
				let result = validator(this.userDialog.user[ keys[i] ]);
				if (!result) {
					anyErrorOccured = true;
				}
			}

			if (anyErrorOccured) {
				this.userDialog.globalErrors = [ this.lviews.errorsInForm ];
				return;
			}
			else {
				this.userDialog.globalErrors = null;
			}

			let response = null;
			if (this.userId == null) {
				// create new user
				let createRequestData = {
					personal_data: {
						first_name: this.userDialog.user.firstName,
						last_name: this.userDialog.user.lastName,
						email: this.userDialog.user.email,
						phone_number: this.userDialog.user.phoneNumber,
						unique_personal_id: (this.userDialog.user.unique_personal_id == "" || this.userDialog.user.unique_personal_id == null) ? null : this.userDialog.user.unique_personal_id,
						birth_date: (this.userDialog.user.birthDate == "" || this.userDialog.user.birthDate == null) ? null : this.userDialog.user.birthDate
					},
					auth_user: {
						username: this.userDialog.user.login,
						send_email: this.userDialog.user.sendActivationMail ? 1 : 0,
						group: [...this.userDialog.user.role, ...this.userDialog.user.dataAccess]
					},
					worker_option: this.workerOptionChosen,
				};
				if(existingUserId){
					createRequestData.user_id=existingUserId
				}
				

				if (this.workerOptionChosen == 3) {
					createRequestData['worker_id'] = this.userDialog.user.workerId;
				}
				this.$emit("show-overlayer", this.lviews.sendingUserCreateRequest);
				try {
					response = await axios({
						url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/users/",
						method: "POST",
						headers: {
							'Authorization': "Bearer " + localStorage.getItem('jwt'),
							'Content-Type': "application/json"
						},
						data: createRequestData
					});
				} catch (error) {
					this.$emit("hide-overlayer");
					response = null;
					this.handleError(error);
				}
			}
			else {
				// edit existing user
				let updateRequestData = this.getEditUserRequestData();
				this.$emit("show-overlayer", this.lviews.sendingUserUpdateRequest);
				try {
					response = await axios({
						url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/users/" + this.userId,
						method: "PATCH",
						headers: {
							'Authorization': "Bearer " + localStorage.getItem('jwt'),
							'Content-Type': "application/json"
						},
						data: updateRequestData
					});
				} catch (error) {
					this.$emit("hide-overlayer");
					response = null;
					this.handleError(error);
				}
			}
			this.$emit("hide-overlayer");
			if (response != null) {
				//this.closeDialog();
				this.closeDialogConfirmCallback();
				this.$emit('refresh-data');
			}
		},

		/**
		 * Handles received error
		 * @param error {Object} Caught error to be handled.
		 */
		handleError: function(error) {
			console.log(error.response)
			if (typeof(error.response) === 'undefined'){
					this.globalErrorMessage='Network error occured. Make sure you are connected to the internet'
					this.userDialog.state = this.dialogStates.ERROR;
					return;
				}
			switch(error.response.status) {
				case 400:
					this.userDialog.globalErrors = [];
					var any = false;

					if(error.response.data.user){
						if(error.response.data.user[0].code=="USER_EXIST_IN_TENANT"){
							this.userDialog.globalErrors.push(this.lviews.errors.error400.user[ error.response.data.user[0].code ])
							any = true;
						}
					}

					if(error.response.data.new_username){
						if(error.response.data.user_id && error.response.data.user_id[0].code=="USER_EXIST_IN_OTHER_TENANT"){
							this.addUserToTenantDialog.visible=true
							this.addUserToTenantDialog.existingUserId = error.response.data.user_id[0].string
						}
						this.userDialog.globalErrors.push(this.lviews.errors.error400.user[ "NEW_USERNAME" ]+error.response.data.new_username[0].string)
						any = true;
					}

					if(error.response.data.username){
						if(error.response.data.username[0].code=="USERNAME_TAKEN"){
							this.userDialog.globalErrors.push(this.lviews.errors.error400.user[ "USERNAME_TAKEN"])
							any = true;
						}
					}

					if (error.response.data.worker_id) {
						for(let item of error.response.data.worker_id){
							this.userDialog.globalErrors.push(this.lviews.errors.error400.worker[ item.code])
						}
						any = true;
					}

					if (error.response.data.worker) {
						this.globalErrorMessage = this.lviews.errors.error400.errors.worker[ error.response.data.worker[0].code ];
						any = true;
						this.userDialog.state = this.dialogStates.ERROR;
					}
					if (error.response.data.worker_option) {
						this.globalErrorMessage = this.lviews.errors.error400.errors.worker_option[ error.response.data.worker_option[0].code ]
						any = true;
						this.userDialog.state = this.dialogStates.ERROR;
					}

					// AuthUser errors handling
					if (error.response.data.auth_user) {
						let d = error.response.data.auth_user;
						if (d.username && d.username[0].code == "unique"){
							this.userDialog.errors.login.isError=true;
							this.userDialog.errors.login.errorMessage=this.lviews.loginTaken;
						}
						else {
							this.globalErrorMessage = this.lviews.errors.anyError.message;
							this.userDialog.state = this.dialogStates.ERROR;
						}
						any = true;
					}

					// UserProfile errors handling
					if (error.response.data.personal_data) {
						let localAny = false; // any error raised within this if (let means the variable won't exist outside this if)
						let d = error.response.data.personal_data;
						if(d.last_name) { // last name generic error
							this.userDialog.errors.lastName.isError = true;
							this.userDialog.errors.lastName.errorMessage = "Invalid last name";
							localAny = true;
						}
						if(d.first_name) { // first name generic error
							this.userDialog.errors.firstName.isError = true;
							this.userDialog.errors.firstName.errorMessage = "Invalid first name";
							localAny = true;
						}
						if(d.personal_email) { // e-mail generic error
							this.userDialog.errors.email.isError = true;
							this.userDialog.errors.email.errorMessage = "Invalid email";
							localAny = true;
						}
						if(d.personal_mobile_number) { // phone number generic error
							this.userDialog.errors.phoneNumber.isError = true;
							this.userDialog.errors.phoneNumber.errorMessage = "Invalid phone number";
							localAny = true;
						}
						if(d.birth_date) { // birth date generic error
							this.userDialog.errors.birthDate.isError = true;
							this.userDialog.errors.birthDate.errorMessage = "Invalid birth date";
							localAny = true;
						}
						if(d.unique_personal_id){
							if(d.unique_personal_id[0].code == "unique"){ // External id already assigned in DB
								this.userDialog.errors.personalId.isError = true;
								this.userDialog.errors.personalId.errorMessage = "External id assigned to another worker";
							}
							else { // Pesel generic error
								this.userDialog.errors.personalId.isError = true;
								this.userDialog.errors.personalId.errorMessage = "Invalid external id";
							}
							localAny = true;
						}

						if(!localAny){
							this.userDialog.globalErrors.push(this.lviews.unrecognizedErrorInBasicDataSection);
						}

						any = true;
					}

					if(!any){
						this.userDialog.globalErrors.push(this.lviews.unrecognizedErrorOcurredWhileSendingForm);
					}
					else if(this.userDialog.globalErrors.length == 0){
						// Show it so the user knows there was an error
						this.userDialog.globalErrors.push(this.lviews.errorsInForm);
					}

					break;

				case 401:
					// this.globalErrorMessage = this.lviews.errors.error401.message;
					// this.userDialog.state = this.dialogStates.ERROR;

					this.$router.push("/login?next=/user-list");

					break;
				case 403:
					// this.globalErrorMessage = this.lviews.errors.error403.message;
					// this.userDialog.state = this.dialogStates.ERROR;

					this.$router.push("/login?next=/user-list");

					break;
				case 404:
					this.userDialog.globalErrors = [
						"User not found"
					];

					break;
				case 429:
					this.userDialog.globalErrors = [
						"Too many request"
					];

					break;
				case 500:
					this.userDialog.globalErrors = [
						"An internal server error has occured. Please report this message to the administrator"
					];
					break;
				default:
					// this.userDialog.globalErrors.push(this.lviews.unrecognizedErrorOcurredWhileSendingForm);
					this.userDialog.globalErrors = [this.lviews.unrecognizedErrorOcurredWhileSendingForm]

					break;
			}
		},

		/**
		 * Updates existing worker id in order to create/update user (with assigned worker instance).
		 * @param newExistingWorkerId {Number} New worker id.
		 */
		updateExistingWorkerId: function(newExistingWorkerId) {
			this.existingWorkerId = newExistingWorkerId;
		},

		isDigit(evt) {
			evt = (evt) ? evt : window.event;
			var charCode = (evt.which) ? evt.which : evt.keyCode;

			if (charCode >= 48 && charCode <= 57){
				return true;
			}
			evt.preventDefault();
		},

		/**
		 * Send request to remove specific user
		 */
		removeAssignedWorker: async function() {
			this.yesNoDialogVisible = false;
			let response = null;
			this.$emit("show-overlayer", this.lviews.sendingUserRemoveRequest);
			let data = {
				worker_option: 7
			}
			try {
				response = await axios({
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/admin/users/" + this.userId,
					method: "PATCH",
					headers: {
						'Authorization': "Bearer " + localStorage.getItem('jwt'),
					},
					data: data
				});
			} catch (error) {
				this.$emit("hide-overlayer");
				response = null;
				this.handleError(error);
			}
			this.$emit("hide-overlayer");

			if (response != null) {
				this.userDialog.user.workerId = null;
				this.userSourceObj.workerId = null;
				this.userDialog.workerState = this.assignedWorkerStates.NOT_ASSIGNED;
				this.workerOptionChosen = 1;
				this.$emit('refresh-data');
			}
		},

		/**
		 * Opens yes-no-dialog in order to ensure whether user wants to remove worker or not
		 */
		openYesNoDialog: function() {
			this.removeUserWorkerContent = this.lviews.yesNoDialogContent.replace("{username}", this.userDialog.user.login);
			this.yesNoDialogVisible = true;
		},

		onAutocompleterInput(model, item){
			this.userDialog.errors.workerId.isError = false;
			this.userDialog.errors.workerId.errorMessages = null;
			this.userDialog.errors.workerOption.isError = false;
			this.userDialog.errors.workerOption.errorMessages = null;

			if(item != null){
				if(!this.userDialog.user.lastName){
					this.userDialog.user.lastName = item.lastName;
				}
				if(!this.userDialog.user.firstName){
					this.userDialog.user.firstName = item.firstName;
				}

				if(!this.userDialog.user.email){
					this.userDialog.user.email = item.email;
				}
				
				if(!this.userDialog.user.phoneNumber){
					this.userDialog.user.phoneNumber = item.phoneNumber;
				}

				if(!this.userDialog.user.personalId){
					this.userDialog.user.personalId = item.personalId;
				}
				
				if(!this.userDialog.user.birthDate){
					this.userDialog.user.birthDate = item.birthDate;
				}
				if(!this.userDialog.user.comment){
					this.userDialog.user.comment = item.comment;
				}

				this.createUserLoginIfPossible();
			}
		}
	},
	
	mounted: function() {
		this.userDialog.minBirthDate = "1920-01-01";
		this.userDialog.maxBirthDate =  new Date().toJSON().slice(0, 10).replace("/-/g", "/");

		this.workerAutocompleteLink = appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v2/admin/worker/";
		
		this.workerOptions[0].label = "No worker";
		this.workerOptions[1].label = "New worker";
		this.workerOptions[2].label = "Existing worker";

		this.userDialog.cancelClick = this.closeDialog;
		this.userDialog.confirmClick = this.saveChanges();
	},

	computed: {
		lviews: {
			get: function() {
				return this.$t('views.userDialog');
			}
		},
	},

	props: {
		userId: {
			type: Number,
			required: false,
			default: null
		},

		userData: {
			type: Object,
			required: false,
			default: null
		},

		roles: {
			type: Array,
			required: true
		},

		dataAccess: {
			type: Array,
			required: true
		},
	},
}
</script>

<style>
.required:after {
	content:" *";
	color: red;
}

.user-dialog-row-item {
	width: 50%;
	padding: 0 10px;
}

@media only screen and (max-width: 550px) {
	.user-dialog-row-item {
		width: 100%;
	}
}
</style>

<style scoped>
	.global-errors {
		color: red;
		text-align: center;
		width: 100%;
		margin: 10px 0;
	}
</style>